body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


a {
  color: #000000;
  text-decoration: none;
}

ul {
  list-style: none;
}

.video-view {
  position: relative;
  width: 300px;
  height: 100px;
  object-fit: cover;
}

.video {
  position: absolute;
  width: 95vw;
  height: 63vh;
  object-fit: cover;

  @media(max-width: 991px) {
    margin-top: 15px;
    max-width: 100%;
    position: absolute;
    width: 98vw;
    height: 60vh;
  }
}

.videoo {
  position: absolute;
  width: 98vw;
  height: 63vh;
  object-fit: cover;

  @media(max-width: 991px) {
    margin-top: 15px;
    max-width: 98%;
    position: absolute;
    width: 98vw;
    height: 60vh;
  }
}

.video-background {
  position: relative;
  width: 100vw;
  height: 100vh;
  object-fit: cover;

}

.videos-background {
  position: relative;
  width: 98vw;
  height: 69vh;
  object-fit: cover;

}

.video-backgrounds {
  position: relative;
  width: 60vw;
  height: 60vh;
  object-fit: cover;

  @media (max-width: 991px) {
    margin-top: 15px;
    max-width: 100%;
    position: relative;
    width: 90vw;
    height: 60vh;
  }
}

.video-view .video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #ccc;
}

.video-view .video-content {
  position: absolute;
  bottom: 0px;
}

.content {
  height: 100%;
  position: absolute;

  @media (max-width: 991px) {
    max-width: 100%;
    position: relative;
  }
}


header {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

nav ul {
  display: flex;
  background-color: #fff;
  margin-bottom: 0px;
}

nav li {
  margin-right: 10px;
}

nav li:last-child {
  margin-right: 0;
}

nav a {
  font-weight: bold;
  font-size: 1.2rem;
  transition: all 0.3s ease;
}

nav a:hover {
  color: #4CAF50;
}